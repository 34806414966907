import React, { useState } from "react";
import { useRef } from "react";
import { NavLink, Link } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import "../CSS/Navbar.css";
import WebSiteLogo from "../img/logo.png";
import Hambrgr from "../img/Hampbrger.png";

const Navbar = () => {
  const navRef = useRef();
  const [activeLink, setActiveLink] = useState("Home");
 

  const handleLinkClick = (activeLink) => {
    setActiveLink(activeLink);
  };
  

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  return (
    <header className="nav-links">
      <Link to="/">
        <div className="logo">
          <img alt="Website Logo" src={WebSiteLogo} />
        </div>
      </Link>

      <nav ref={navRef}>
        <NavLink exact to="/" onClick={() => handleLinkClick("Home")}>
          Home
        </NavLink>

        <NavLink to="/Aboutus" onClick={() => handleLinkClick("About")}>
          About
         
        </NavLink>

        <NavLink to="/Services" onClick={() => handleLinkClick("Services")}>
          Services
        </NavLink>

        <NavLink to="/Internships" onClick={() => handleLinkClick("Internships")}>
         Internships
        </NavLink>

        <NavLink to="/verification " onClick={() => handleLinkClick("CIR Verification")}>
        CIR Verification
        </NavLink>

        <NavLink to="/Contactus" onClick={() => handleLinkClick("Contact")}>
          Contact Us
        </NavLink>

        <button className="nav-btn nav-close-btn" onClick={showNavbar}>
          <FaTimes />
        </button>
      </nav>

      <button className="nav-btn" onClick={showNavbar}>
     
        <img style={{ height: "3rem", width: "3rem" }} src={Hambrgr} alt="Menu" />
        <span style={{ color: "white", fontSize: "1.5rem", fontWeight: "bold" }}>Menu</span>
      </button>
    </header>
  );
};

export default Navbar;
