import React from "react";
import "../CSS/Services.css";
import programmming from "../img/websitedevelopent.png";
import Databases from "../img/demonew.gif";
import Java from "../img/java.png";
import Android from "../img/Android.png";
import Pythonprog from "../img/pythonprogramming.png";

const Services = () => {
  return (
    <>
      <div className="company">
        <div className="img">
          <img src={programmming} alt="" />
        </div>
        <div className="company-info">
          <h2
            style={{
              textAlign: "center",
              color: "#13abe2",
              fontFamily: "serif",
              fontWeight: "bold",
            }}
          >
            Website Development
          </h2>
          <p>
            Our Website Developers Provide Expert Web Application Development
            And Web Design Services To Our Clients. TECH MENTORSHIP Offers A
            Variety Of Website Design And Development Services, We Specialize In
            Developing Interactive, Scalable, Brand-Oriented, And Business-Ready
            Custom Web Solutions. Our Aim Is To Build Profitable Digital
            Products Engineered To Spark Creativity And Boost ROI.
          </p>
        </div>
      </div>

      <div className="company">
        <div className="company-info">
          <h2
            style={{
              textAlign: "center",
              color: "#13abe2",
              fontFamily: "serif",
              fontWeight: "bold",
            }}
          >
            Data Science
          </h2>
          <p>
            Data Science Internships Are A Unique Chance To Gain
            Experience In The Midst Of The Virtual Workforce While Remaining
            Immersed In One Of The Top Organizations In The Field. Data Analysis
            Internships Are Some Of The Most Competitive And Popular Within The
            Broader Data Science Field.
          </p>
        </div>

        <div className="img">
          <img loading="lazy" src={Databases} alt="" />
        </div>
      </div>

      <div className="company">
        <div className="img">
          <img loading="lazy" src={Java} alt="" />
        </div>

        <div className="company-info">
          <h2
            style={{
              textAlign: "center",
              color: "#13abe2",
              fontFamily: "serif",
              fontWeight: "bold",
            }}
          >
            Java Developerment
          </h2>
          <p>
            Creative Designers Offer Outstanding And Attention-Grabbing Visuals
            For Brochures, Banners, Flyers, Logos, Business Cards, E-Books, And
            Many More. Our Graphic Designs Can Develop A Great Impression On
            Your Prospect’s Mind. With A Complete Mixture Of Professionalism And
            Stunning Creativity Skills, Our Expert Designers Can Establish A
            Bright Profile For Your Business In The Online World.
          </p>
        </div>
      </div>

      <div className="company">
        <div className="company-info">
          <h2
            style={{
              textAlign: "center",
              color: "#13abe2",
              fontFamily: "serif",
              fontWeight: "bold",
            }}
          >
            Android Application Developerment
          </h2>
          <p>
            Are you ready to dive into the dynamic world of Android app
            development? Look no further! Tech Mentorship proudly offers an
            immersive internship program designed to empower aspiring developers
            with the skills and knowledge needed to excel in the ever-evolving
            landscape of mobile applications.
          </p>
        </div>
        <div className="img">
          <img loading="lazy" src={Android} alt="" />
        </div>
      </div>

      <div className="company">
        <div className="img">
          <img loading="lazy" src={Pythonprog} alt="" />
        </div>

        <div className="company-info">
          <h2
            style={{
              textAlign: "center",
              color: "#13abe2",
              fontFamily: "serif",
              fontWeight: "bold",
            }}
          >
            Python Programming
          </h2>
          <p>
            Are you ready to unleash the power of Python and take your programming skills to the next level? Look no further! Tech Mentorship proudly offers an enriching internship program designed to empower aspiring developers with the skills and knowledge needed to excel in the dynamic field of Python programming.
          </p>
        </div>
      </div>
    </>
  );
};

export default Services;
