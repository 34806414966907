import React from "react";
import { faLinkedin, faWhatsapp, faTelegram } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../CSS/SocialIcons.css';

const SocialIcons = () => {
    return (
        <div className="social-div">
            <a href="https://www.linkedin.com/in/techmentorship/">
                <FontAwesomeIcon icon={faLinkedin} />
            </a>
         
            <a href="https://t.me/TechMentorshipCommunity">
                <FontAwesomeIcon icon={faTelegram} />
            </a>
            <a href="https://chat.whatsapp.com/LZO9hYZ1JUKCNSYOZ76JVB">
                <FontAwesomeIcon icon={faWhatsapp} />
            </a>
        </div>
    )
}

export default SocialIcons;
