// import React, { useEffect } from 'react';

// const VisitorCounter = () => {
//   useEffect(() => {
//     const script = document.createElement('script');
//     script.src = 'https://counter11.optistats.ovh/private/counter.js?c=ybhaqytf8zp4gmyt4ajz31gsb63mf863&down=async';
//     script.async = true;
//     document.body.appendChild(script);

//     return () => {
//       // Cleanup: remove the script when the component unmounts
//       document.body.removeChild(script);
//     };
//   }, []);

//   return (
//     <div id="sfcybhaqytf8zp4gmyt4ajz31gsb63mf863">
//       {/* NoScript fallback */}
//       <noscript>
//         <a
//           href="https://www.freecounterstat.com"
//           title="website counter"
//         >
//           <img
//             src="https://counter11.optistats.ovh/private/freecounterstat.php?c=ybhaqytf8zp4gmyt4ajz31gsb63mf863"
//             border="0"
//             title="website counter"
//             alt="website counter"
//           />
//         </a>
//       </noscript>
//     </div>
//   );
// };

// export default VisitorCounter;


import React, { useEffect } from 'react';

const VisitorCounter = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://counter11.optistats.ovh/private/counter.js?c=c6x8kp91elumyn7k2a17lf32pacpyy6w&down=async';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div id="sfcc6x8kp91elumyn7k2a17lf32pacpyy6w">
      {/* NoScript fallback */}
      <noscript>
        <a href="https://www.freecounterstat.com" title="web counter">
          <img src="https://counter11.optistats.ovh/private/freecounterstat.php?c=c6x8kp91elumyn7k2a17lf32pacpyy6w" border="0" title="web counter" alt="web counter" />
        </a>
      </noscript>
    </div>
  );
};

export default VisitorCounter;
