import React from "react";
import "../CSS/Internships.css";

const Internships = () => {
  return (
    <div>
      <div className="intern-halfback">
        <h5 className="Offer">
          <button> INTERNSHIPS WE OFFER !</button>
        </h5>

        <h1
          className="heading"
          style={{
            marginTop: "3rem",
            textAlign: "center",
            fontWeight: "bolder",
            fontSize: "3rem",
            color: "#13abe2",
            fontFamily: "serif",
          }}
        >
          Internship To Enrich Your Career And Mind
        </h1>
        <div className="InterContenst-new">
          <li>
            <b>Mission: </b> Tech Mentorship empowers youth, especially students,
            by fostering self-awareness and exploration in technology and high
            growth fields.
          </li>
          <li>
            <b>Bridging the Gap: </b> We connect students with industry
            knowledge, guiding them towards their dream careers.
          </li>
          <li>
            <b>MSME Approved: </b> As an MSME-registered startup, we welcome
            individuals who share our passion for continuous learning and
            growth.
          </li>
          <li>
            <b>AICTE Approved: </b> LOur internship programs, endorsed by AICTE,
            ensure top-notch quality and relevance, preparing students for
            successful careers in the tech industry.
          </li>
          <li>
            <b>Tech Mentorship: </b> an MSME registered startup, is dedicated to
            empowering youth through AICTE-approved internship programs in
            technology and high-growth fields. Committed to international
            standards with ISO 9001:2020 certification, we bridge the gap between
            students and industrial knowledge, inviting individuals to join us
            on a journey of perpetual learning and joy.
          </li>

          <div className="line"></div>
        </div>
      </div>

      {/* Cards -1*/}

      <div className="main-box-sub">
        <div className="child">
          <div className="image-class-new">
            <img
              alt="logo"
              src="https://oasisinfobyte.com/image/wbd.svg"
              loading="lazy"
            ></img>
          </div>
          <h5 className="offer-high-light">Web Development</h5>
          <p>
            The Web Development Internship Offers Practical Work Experience And
            An Introduction To Creating And Improving Web-Based Systems. This
            Internship Will Involve Challenging Opportunities, Real World
            Projects. It’s A Fantastic Opportunity To Gain Hands-On Experience
            In The Web And/Or App Development Field With An Award-Winning
            Innovation Team.
          </p>
          <div className="ApplyBtn">
            {" "}
          <a  href="https://docs.google.com/forms/d/e/1FAIpQLSfQeA5u08d13uuK7xQpVWUGRNO65Re2Ky8owli2BOfKiyBI9w/viewform"> <button>Apply Now</button></a> 
          </div>
        </div>
        <div className="child">
          <div className="image-class-new">
            <img
              alt="logo"
              src="https://oasisinfobyte.com/image/Investment%20data-amico.svg "
              loading="lazy"
            ></img>
          </div>
          <h5 className="offer-high-light">Data Science</h5>
          <p>
            Immerse Yourself In The Realm Of Data With Our Internship At{" "}
            <b>Tech Mentorship.</b> This Hands-On Experience Provides Real-World
            Projects, Challenges, And Exposure To Cutting-Edge Analytics. Join
            Our Award-Winning Team, Contribute To Impactful Initiatives, And
            Gain Practical Insights Into The Dynamic Field Of Data Analytics
          </p>
          <div className="ApplyBtn">
            {" "}
            <a  href="https://docs.google.com/forms/d/e/1FAIpQLSfQeA5u08d13uuK7xQpVWUGRNO65Re2Ky8owli2BOfKiyBI9w/viewform"> <button>Apply Now</button></a> 
          </div>
        </div>

        <div className="child">
          <div className="image-class-new">
            <img
              alt="logo"
              src="https://oasisinfobyte.com/image/Usability%20testing-bro.svg"
              loading="lazy"
            ></img>
          </div>

          <h5 className="offer-high-light">Android Development</h5>
          <p>
            Android Is An Open Source Operating For Mobile Application
            Development.Now A Days People Continuously Shifting To Mobile
            Devices To Access Internet Application And Other Important
            Tasks.Which Is More Easy Than To Work On PC.It It Best Choice To
            Getting Started Your First App With <b>Tech Mentorship.</b>
          </p>
          <div className="ApplyBtn">
            {" "}
            <a  href="https://docs.google.com/forms/d/e/1FAIpQLSfQeA5u08d13uuK7xQpVWUGRNO65Re2Ky8owli2BOfKiyBI9w/viewform"> <button>Apply Now</button></a> 
          </div>
        </div>
      </div>

      {/* Cards -2*/}
      <div className="main-box-sub">
        <div className="child">
          <div className="image-class-new">
            <img
              alt="logo"
              src="https://oasisinfobyte.com/image/Binary%20code-amico.svg"
              loading="lazy"
            ></img>
          </div>
          <h5 className="offer-high-light">Python Programming</h5>
          <p>
            Enroll In Our Intensive 4-6 Week Internship Program To Become
            Proficient In Python Programming, All Within The Convenience Of Your
            Home. Acquire The Essential Skills And Expertise To Pursue Exciting
            Job Prospects In This Field. Throughout The Program, You'll Delve
            Into Various Aspects, Spanning From Web Development To Deploying
            Python-Driven Web Applications. Achieve Certification And Elevate
            Your Career Possibilities. Seize This Chance To Excel In Python
            Programming – It's Not To Be Missed!
          </p>
          <div className="ApplyBtn">
            {" "}
            <a  href="https://docs.google.com/forms/d/e/1FAIpQLSfQeA5u08d13uuK7xQpVWUGRNO65Re2Ky8owli2BOfKiyBI9w/viewform"> <button>Apply Now</button></a> 
          </div>
        </div>
        <div className="child">
          <div className="image-class-new">
            <img
              alt="logo"
              src="https://oasisinfobyte.com/image/Coworking-rafiki%20(1).svg"
              loading="lazy"
            ></img>
          </div>
          <h5 className="offer-high-light">Java Programming</h5>
          <p>
            Master The Fundamentals Of Programming In Java From The Comfort Of
            Your Home And Get Certified To Apply For Great Job Opportunities. A
            With 4-Week Comprehensive Internship Program Where You Will Learn
            Right From Development To Deployment Of Web Applications Made Using
            Java. Get First-Hand Exposure To Working In The Real World, Build
            Your Professional Network, Integrate Classroom Knowledge And Theory
            With Practical Application And Skills Developed In Professional Or
            Community Settings.
          </p>
          <div className="ApplyBtn">
            {" "}
            <a  href="https://docs.google.com/forms/d/e/1FAIpQLSfQeA5u08d13uuK7xQpVWUGRNO65Re2Ky8owli2BOfKiyBI9w/viewform"> <button>Apply Now</button></a> 
          </div>
        </div>

        <div className="child">
          <div className="image-class-new">
            <img
              alt="logo"
              src="https://oasisinfobyte.com/image/Private%20data-bro.svg"
              loading="lazy"
            ></img>
          </div>

          <h5 className="offer-high-light">Cyber Security</h5>
          <p>
            Step Into The Realm Of Cybersecurity With Our Internship At{" "}
            <b>Tech Mentorship.</b> This Hands-On Experience Features Real-World
            Projects, Challenges, And Exposure To Cutting-Edge Security
            Practices. Join Our Esteemed Cybersecurity Team, Contribute To
            Impactful Initiatives, And Gain Practical Insights Into The Dynamic
            Field Of Cybersecurity. Apply Now For A Transformative Journey Into
            The Heart Of Cybersecurity Innovation!
          </p>
          <div className="ApplyBtn">
            {" "}
            <button>Coming Soon..</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Internships;
