import React from "react";
import VisitorCounter from "./homecompo/VisitorCounter";
import "../CSS/Navbar.css";
import { NavLink, Link } from "react-router-dom";
import "../CSS/Footer.css";
import { MDBFooter, MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { MDBIcon } from "mdbreact";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTelegram,
  faLinkedin,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  const telegramLink = `https://t.me/TechMentorshipCommunity`;

  return (
    <MDBFooter
      className="text-center text-lg-start text-muted"
      style={{
        height: "auto",
        margin: "0",
        padding: "0",
        background: "linear-gradient(to top, #09203f 0%, #537895 100%)",
      }}
    >
      <section
        style={{
          background: "linear-gradient(to top, #09203f 0%, #537895 100%)",
          color: "var(--textColor) ",
        }}
        className=""
      >
        <MDBContainer className="text-center text-md-start mt-0 ">
          <MDBRow className="mt-3">
            <MDBCol md="3" lg="4" xl="3" className="mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">
                <MDBIcon icon="heart" className="me-3 mt-4" />
                Tech Mentorship
              </h6>
              <p>
                Tech Mentorship Pvt Ltd. is an innovative IT software
                development company offering corporate training and seeking a
                Softwere Development Trainee to drive solution growth and expand
                market reach.
              </p>
            </MDBCol>

            <MDBCol
              md="4"
              lg="3"
              xl="3"
              className="mx-auto mb-md-0 mb-4 nav-links"
            >
              <h6 className="text-uppercase fw-bold mb-4 mt-4">Quick Links</h6>
              <div className="row">
                <div className="col">
                  <p>
                    <NavLink to="/">
                      {" "}
                      <span className="text-reset">Home</span>
                    </NavLink>
                  </p>
                  <p>
                    <NavLink to="/Aboutus">
                      {" "}
                      <sapn className="text-reset">About</sapn>
                    </NavLink>
                  </p>
                </div>
                <div className="row">
                  <p>
                    <NavLink to="Services">
                      {" "}
                      <span className="text-reset">Services</span>
                    </NavLink>
                  </p>
                  <p>
                    <NavLink to="Internships">
                      {" "}
                      <span className="text-reset">Internships</span>
                    </NavLink>
                  </p>
                </div>
                <div className="row">

                <p>
                    <NavLink to="verification">
                      {" "}
                      <span className="text-reset">CIR Verification</span>
                    </NavLink>
                  </p>
                  <p>
                    <NavLink link to="Contactus">
                      {" "}
                      <span className="text-reset">Contact</span>
                    </NavLink>
                  </p>
                </div>
              </div>
            </MDBCol>

            <MDBCol
              md="4"
              lg="3"
              xl="3"
              className="mx-auto mb-md-0 mb-4 nav-links"
            >
              <h6 className="text-uppercase fw-bold mb-4 mt-4">Follow Us</h6>
              <div className="col">
                <div className="col">
                  <p>
                    <a style={{ textDecoration: "none", color: "white" }} href={telegramLink} target="_blank" rel="noreferrer">
                      <FontAwesomeIcon icon={faTelegram} /> Telegram
                    </a>
                  </p>
                  <p>
                    <a style={{ textDecoration: "none", color: "white" }} href="https://www.linkedin.com/in/techmentorship/"  target="_blank" rel="noreferrer">
                      <FontAwesomeIcon icon={faLinkedin} /> Linkdin </a>
                  </p>
                </div>
                <div className="row">

                  <p>
                    <a
                      style={{ textDecoration: "none", color: "white" }}
                      href="https://chat.whatsapp.com/LZO9hYZ1JUKCNSYOZ76JVB"
                      target="_blank" rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
                    </a>
                  </p>
                </div>
                <div className="row">
                  <p></p>
                </div>
              </div>
            </MDBCol>

            <MDBCol md="4" lg="3" xl="3" className="mx-auto mb-md-0 mb-4">
              <h6 className="text-uppercase fw-bold mb-4 mt-4">Contact</h6>
              <p>
                <MDBIcon icon="home" className="me-2" />
                A-1183 N.S.MAzadpur Delhi-33, Azadpur, New Delhi, Delhi 110033
              </p>
              <p>
                <MDBIcon icon="envelope" className="me-3" />
                techmentorshiphr@gmail.com
              </p>
              <p>
                <MDBIcon icon="phone" className="me-3" /> +91 6207615006
              </p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div
        className="text-center p-4"
        style={{
          background: "linear-gradient(to top, #09203f 0%, #537895 100%)",
          color: "var(--textColor)",
        }}
      >
        <span>
          {" "}
          <VisitorCounter></VisitorCounter>{" "}
        </span>
        © 2024 Copyright:
        <Link to="/"> Tech Mentorship</Link>
      </div>
    </MDBFooter>
  );
};

export default Footer;
