import React, { useState } from 'react';
import axios from 'axios';

const VerifyCertificate = () => {
  const [number, setNumber] = useState('');
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false); // Add loading state

  const handleVerify = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading
    setResult(null);  // Clear previous result
    try {
      const response = await axios.get(`https://tech-mentor-server.onrender.com/certificates/${number}`);
      setResult(response.data);
    } catch (error) {
      setResult({ message: 'Certificate Not Found ❌', color:'red' });
      console.error('Error verifying certificate', error);
    }
    setLoading(false); // Stop loading
  };

  return (
    <div className="verify-container">
      <h2>Verify Certificate</h2>
      <form onSubmit={handleVerify}>
        <input
          type="text"
          placeholder="Certificate Number"
          value={number}
          onChange={(e) => setNumber(e.target.value)}
          required
        />
        <button type="submit">Verify</button>
      </form>
      
      {loading ? ( // Show loader if loading
        <div style={{alignItems:'center',justifyContent:'center',textAlign:'center'}} className="loader"><img alt='loding..' src='https://media.tenor.com/On7kvXhzml4AAAAj/loading-gif.gif'></img></div>
      ) : (
        result && (
          <div className="result">
            <p style={{ fontWeight:'bolder', color: result.status === 'success' ? 'green' : 'red' }}>{result.message}</p>
            {result.owner && <p>Owner: {result.owner}</p>}
            {result.certificateType && <p>Certificate Type: {result.certificateType}</p>}
          </div>
        )
      )}
    </div>
  );
};

export default VerifyCertificate;

