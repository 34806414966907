// AboutUs.js
import React from "react";
import "../CSS/Aboutus.css";
import about_us_img from '../img/about-us.png'
import missons from '../img/missions.png'
import vision from '../img/visons.png'
import OwnerLogo from "../img/ownerlogomain.png"


const AboutUs = () => {
  return (
    <>
      <div className="company">
        <div className="company-img">
          <img
            loading="lazy"
            src={about_us_img}
            alt="logo"
          />
        </div>
        <div className="company-info">
          <h2
            style={{
              textAlign: "center",
              color: "#13abe2",
              fontFamily: "serif",
              fontWeight: "bolder",
            }}
          >
            {" "}
            Build IT That Builds Your Business
          </h2>
          <p>
            <b>Tech Mentorship</b> We believe practical knowledge is the key to
            success in the tech industry. Our aim is to help students lacking
            basic skills by offering hands-on learning through live projects and
            real-world examples. Our team consists of industry experts who are
            dedicated to equipping interns with the knowledge and skills needed
            to succeed in their careers. We foster an inclusive and supportive
            environment that encourages learning, growth, and creativity.
          </p>
        </div>
      </div>

      {/* Mession And Vision Content */}
      <h1
        style={{
          textAlign: "center",
          color: "#13abe2",
          fontFamily: "serif",
          fontWeight: "bolder",
        }}
        className="misson-visson-header"
      >
        Mission & Vision{" "}
      </h1>
     
      <div className="content-main-box-about">

        <div className="content-box-about">
          <div className="testdemp">
            <img alt="misson" className="logo-of-missons" src={missons}></img>
            <h4>Misson</h4>
          </div>
          <p className="missonandvissoncontent">
            {" "}
            Software development is an iterative process, and the mission
            includes continually improving software solutions. This entails
            gathering feedback, identifying areas for enhancement, and
            implementing updates to make the software more efficient,
            user-friendly, and reliable. Continuous improvement also involves
            staying up to date with the latest technologies, tools, and industry
            trends.
          </p>
        </div>

        <div className="content-box-about">
          <div className="testdemp">
            <img alt="logoofmissons" className="logo-of-missons" src={vision}></img>
            <h4>Vision</h4>{" "}
          </div>
          <p className="missonandvissoncontent">
            The vision of software development is to create innovative solutions
            that push the boundaries of technology. This involves leveraging
            emerging technologies, exploring new ideas, and developing software
            that brings novel approaches to solving problems and improving the
            lives of users.
          </p>
        </div>
      </div>


      
      {/* Founder Section  */}

      <div className="founder-container">
        <div className="founder-image">
          <img src={OwnerLogo} alt="Founder" />
        </div>
        <div className="founder-details">
          <h2 style={{
            textAlign: "center",
            color: "#13abe2",
            fontFamily: "serif",
            fontWeight: "bolder",
          }}>Tech Mentorship Pvt Ltd.</h2>
          <p>
           
          </p>
        </div>

      </div>



    </>
  );
};

export default AboutUs;
