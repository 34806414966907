import React from "react";
import "../homecompo/WeOffer.css";
import community from '../../img/community.png';

const WeOffrer = () => {
  return (
    <>
      <h1 style={{textAlign:'center',color:'#13abe2',fontFamily:"serif",fontWeight:'bold',}}>WHAT WE OFFER !</h1>
      <div className="main-box">
        <div className="child">
          <div className="image-class">
            <img alt="logo" src={community} loading="lazy"></img>
          </div>
          <h5 className="offer-high-light">Community</h5>
          <p className="desc">
            Meeting new students, making connections, and building relationships
            will all help down the line when you’re looking for a job. Because
            you’re all part of the same organisation, you know the people your
            meeting have at least a few of the same interests as you, so their
            connections will be valuable.
          </p>
        </div>
        <div className="child">
        <div className="image-class">
            <img alt="logo" src="https://letsgrowmore.in/wp-content/uploads/2021/05/huumans3-5.png " loading="lazy"></img>
          </div>
          <h5 className="offer-high-light">Real Time Skill Development</h5>
        
          <p className="desc">
            By participating in a student organisation you can develop soft
            skills that are essentially “people skills” — they’re the skills
            that allow us to effectively interact with others, like
            communication, attitude, and work ethic. We will not only teach you
            these skills, but will also helps you broaden and improve those you
            already have.
          </p>
        </div>

        <div className="child">
        <div className="image-class">
            <img alt="logo" src="https://letsgrowmore.in/wp-content/uploads/2021/05/huumans3-5.png" loading="lazy"></img>
          </div>

          <h5 className="offer-high-light">Immense Learning</h5>
         
          <p className="desc">
            By participating in a student organisation you can develop soft
            skills that are essentially “people skills” — they’re the skills
            that allow us to effectively interact with others, like
            communication, attitude, and work ethic. We will not only teach you
            these skills, but will also helps you broaden and improve those you
            already have.
          </p>
        </div>
      </div>
    </>
  );
};

export default WeOffrer;
