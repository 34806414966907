import React from "react";
import "../CSS/ContactUs.css";
import cover from "../img/cover.png";
import SocialIcons from "./SocialIcons";

const ContactUs = () => {
  return (
    <>
      <div
        className="main-new-contect"
        style={{
          backgroundImage: `url(${cover})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          width: "100%",
          height: "700px", // Adjust the height as needed
        }}
      >
        <div className="content-wrapper">
          {/* First div for content */}
          <div className="content">
            <h2>Would You Like To Contact Us?</h2>
            <p>
              Connect with us to embark on a journey of endless learning and
              boundless joy!
            </p>

            <SocialIcons></SocialIcons>
          </div>

          {/* Second div for image */}
          <div className="image">
            {/* Add the image */}
            <img
              src="https://letsgrowmore.in/wp-content/uploads/2021/05/undraw_voice_interface_eckp.png"
              alt="logi"
            />
          </div>
        </div>
      </div>

      {/* New Section Started */}
      <div className="company-c">
        <div className="company-info-c">
          <span className="our-c">1</span>
          <h2 className="ahto">Mail Us</h2>
          <p>
            Got something on your mind? We're all ears! Whether it's a thought,
            a question, or a spark of inspiration, drop us a line. Your voice
            matters to us, and we're here to listen. Reach out to us via email
            and let's start a conversation!
          </p>
          <b> techmentorshiphr@gmail.com</b>
        </div>

        <div className="img-c">
          <img
            loading="lazy"
            src="https://letsgrowmore.in/wp-content/uploads/2021/05/undraw_manage_chats_ylx0.png"
            alt=""
          />
        </div>
      </div>

      <div className="company-c">
        <div className="img-c">
          <img
            loading="lazy"
            src="https://letsgrowmore.in/wp-content/uploads/2021/05/undraw_urban_design_kpu8.png"
            alt=""
          />
        </div>

        <div className="company-info-c">
          <span className="our-c">2</span>
          <h2 className="ahto">Call Us</h2>
          <p className="para-aboout">
            For matters of utmost importance, skip the wait and let's connect by
            phone. Your urgency is our priority!
          </p>
          <b>+91 6207615006</b>
        </div>
      </div>

      <div className="company-c">
        <div className="company-info-c">
          <span className="our-c">3</span>
          <h2 className="ahto">Join Us</h2>
          <p>
            Join our community of opportunity-seekers! Register now for exciting
            openings and be part of something extraordinary
          </p>
          <div>
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSfQeA5u08d13uuK7xQpVWUGRNO65Re2Ky8owli2BOfKiyBI9w/viewform">   <button className="explore-btn">
              Explore <i className="fas fa-arrow-right"></i>
            </button></a>
          </div>
        </div>

        <div className="img-c">
          <img
            loading="lazy"
            src="https://letsgrowmore.in/wp-content/uploads/2021/05/undraw_directions_x531-min-1536x1072.png"
            alt=""
          />
        </div>
      </div>
    </>
  );
};

export default ContactUs;
