import './App.css';
import Footer from './components/Footer';
import Home from './components/Home';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ErrorPage from './components/ErrorPage';
import Navbar from './components/Navbar';
import Aboutus from './components/Aboutus';
import Services from './components/Services';
import Internships from './components/Internships';
import ContactUs from './components/ContactUs';
import Login from './components/Admin/Login';
import Dashboard from './components/Admin/Dashboard';
import VerifyCertificate from './components/Admin/VerifyCertificate';
 

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        
        <Route path='/' element={<Home />}></Route>
        <Route path='/Aboutus' element={<Aboutus/>}></Route>
        <Route path='/Services' element={<Services/>}></Route>
        <Route path='/Internships' element={<Internships/>}></Route>
        <Route path='/Contactus' element={<ContactUs/>}></Route>
        <Route path='/login' element={<Login/>}></Route>
        <Route path='/dashboard' element={<Dashboard/>}></Route>
        <Route path='/verification' element={<VerifyCertificate/>}></Route>
        {/* Add the error route */}
        <Route path='*' element={<ErrorPage />} />

      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
