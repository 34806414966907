import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const [certificates, setCertificates] = useState([]);
  const [number, setNumber] = useState('');
  const [owner, setOwner] = useState('');
  const [certificateType, setCertificateType] = useState('');  // New state for certificateType
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchCertificates();
  }, []);

  const fetchCertificates = async () => {
    try {
      const response = await axios.get('https://tech-mentor-server.onrender.com/certificates', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setCertificates(response.data);
    } catch (error) {
      console.error('Error fetching certificates', error);
    }
  };

  const handleAddCertificate = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        'https://tech-mentor-server.onrender.com/certificates',
        { number, owner, certificateType },  // Include certificateType in the request
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      );
      setCertificates([...certificates, response.data]);
      setNumber('');
      setOwner('');
      setCertificateType('');  // Clear the certificateType input
      setMessage('Certificate added successfully');
      setError('');
    } catch (error) {
      setMessage('');
      setError(error.response?.data?.error || 'Error adding certificate');
      console.error('Error adding certificate', error);
    }
  };

  const handleDeleteCertificate = async (number) => {
    try {
      await axios.delete(`https://tech-mentor-server.onrender.com/certificates/${number}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setCertificates(certificates.filter((cert) => cert.number !== number));
    } catch (error) {
      console.error('Error deleting certificate', error);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  return (
    <div className="dashboard-container">
      <h2>Admin Dashboard</h2>
      <button onClick={handleLogout}>Logout</button> <br/><br/>
      <form onSubmit={handleAddCertificate}>
        <input
          type="text"
          placeholder="Certificate Number"
          value={number}
          onChange={(e) => setNumber(e.target.value)}
          required
        />
        <input
          type="text"
          placeholder="Owner Name"
          value={owner}
          onChange={(e) => setOwner(e.target.value)}
          required
        /> 
        <select
          className='OptionDropdown'
          value={certificateType}
          onChange={(e) => setCertificateType(e.target.value)}
          required
        >
          <option value="">Select Certificate Type</option>
          <option value="Pro">Pro</option>
          <option value="Non-Pro">Non-Pro</option>
        </select>

  
        
        <button type="submit">Add Certificate</button>
      </form>
      {message && <p style={{ color: 'green' }}>{message}</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>}


      <div className="certificates-list">
        <h3>Certificates</h3>
        <div class="table-container">
  <table>
    <thead>
      <tr>
        <th>Certificate Number</th>
        <th>Owner</th>
        <th>Certificate Type</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody>
      {certificates.map((cert) => (
        <tr key={cert.number}>
          <td>{cert.number}</td>
          <td>{cert.owner}</td>
          <td>{cert.certificateType}</td>
          <td>
            <button onClick={() => handleDeleteCertificate(cert.number)}>Delete</button>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
</div>

      </div>
    </div>
  );
};

export default Dashboard;



