import React, { useState } from "react";
import { questions } from "../../dataquestion";
import MyAccordion from "./MyAccordion";
import "../faqsection/accordian.css";
import faqsecimg from '../../img/faqsec.gif'

const Accordion = () => {
  const [data, setData] = useState(questions);
  return (
    <>
      <section className="main-div">
        <h1
          style={{
            fontWeight:'bolder',
            textAlign: "center",
            color: "#13abe2",
            fontFamily: "serif",
            fontSize:'3rem',
          }}
        >
          {" "}
          FAQ
        </h1>
        <div className="content-wrapper">
          <div className="accordion-section">
            <img
              src={faqsecimg}
              alt="Image"
            />
          </div>
          <div className="accordion-section">
            {data.map((curElem) => {
              const { id, question, answer } = curElem;
              return <MyAccordion key={id} {...curElem} />;
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default Accordion;
