import React from "react";
import "../CSS/Home.css";
import WeOffrer from "./homecompo/WeOffrer";
import Accordion from "./faqsection/Accordion";
import Intterrnship from "./Internships";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <>

<div className="content-main-box-home">
    <div className="content-box-home">
        <h1 className="slogantitle">Empowering Growth, One Code at a Time.</h1>
        <p className="OurSlogoan">
            <b>Tech Mentorship </b>are IT services and IT consultancy that
            specializes in creating innovative solutions for businesses. We
            are passionate about technology and believe in the power of
            software to transform the world. Our internship program is just
            one of the ways in which we are investing in the future of the
            industry.
        </p>
       <Link style={{textDecoration:'none'}} to='/Internships'> <button className="know-more-btn">Internships </button></Link>
    </div>
</div>



   

      <div className="whatweofferclss">
        <WeOffrer></WeOffrer>
      </div>

      {/* internship */}
      <Intterrnship></Intterrnship>

      {/* Our Support Section */}
      <div className="Our-Supports-main-box">
        <div className="databox">
          <i class="fa-solid fa-person"></i> <h1>2K+</h1>
          <p>STUDENTS MENTORED </p>
        </div>

        <div className="databox">
          <i class="fa-solid fa-video"></i> <h1>300+</h1>
          <p>SESSIONS FINISHED</p>
        </div>

        <div className="databox">
          <i class="fa-brands fa-slack"></i> <h1>300+</h1>
          <p>STUDENTS PLACEMENT</p>
        </div>

        <div className="databox">
          <i class="fa-brands fa-google"></i> <h1>4.8K</h1>
          <p>GOOGLE RATINGS</p>
        </div>
      </div>

      {/* FaQ Section */}
      <Accordion></Accordion>
    </>
  );
};

export default Home;
